import axios from 'axios';

import { serviceUserJwt } from './serviceConfig';

export async function getDashboardData() {
  let workQueueFilterResponse = [];
  try {
    const url = '/buow_api/get-dashboard-cards';
    // console.log('get get-dashboard-cards- getting work queue: Calling ' + url);

    await axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: serviceUserJwt,
        },
      })
      .then((response) => {
        workQueueFilterResponse = response;
      });
  } catch (ex) {
    console.log('Error in get get-dashboard-cards ', ex.message);
  }
  return workQueueFilterResponse;
}

export async function getUserPreferencesData() {
  let workQueueFilterResponse = [];
  let user = JSON.parse(sessionStorage.getItem('user_information'));
  try {
    const url = process.env.REACT_APP_BACKEND_URL + `/buow_api/secroledashboardcardxref/?contextId=${user.contextId}&role=${user['rolesName']}`;
    // console.log('user-preference/' + JSON.parse(sessionStorage.getItem('user_information')).userId + ' getting work queue: Calling ' + url);

    await axios.get(url, { headers: { 'Content-Type': 'application/json' } }).then((response) => {
      workQueueFilterResponse = response;
    });
  } catch (ex) {
    console.log('Error in get get-user-preference', ex.message);
  }

  return workQueueFilterResponse;
}

export async function updateUserPreferenceData(payload) {
  let workQueueFilterResponse = [];
  let user = JSON.parse(sessionStorage.getItem('user_information'));
  try {
    const url = process.env.REACT_APP_BACKEND_URL + `/buow_api/secroledashboardcardxref?contextId=${user.contextId}&cardId=${payload.cardId}&role=${user.rolesName}`;
    console.log('update-user-preference?userId=' + JSON.parse(sessionStorage.getItem('user_information')).userId + ' updating work queue: Calling ' + url);
    await axios.post(url, payload, { headers: { 'Content-Type': 'application/json' } }).then((response) => {
      workQueueFilterResponse = response;
    });
  } catch (ex) {
    console.log('Error in get get-user-preference', ex.message);
  }
  return workQueueFilterResponse;
}

export async function getIndicatorInteration(indicatorId, currentIteration, bufferSize) {
  let workQueueFilterResponse = [];
  try {
    const url =
      '/buow_api/get-indicators-iteration?userId=' +
      JSON.parse(sessionStorage.getItem('user_information')).userId +
      '&indicatorId=' +
      indicatorId +
      '&currentIteration=' +
      currentIteration +
      '&bufferSize=' +
      bufferSize;
    console.log('get-indicators-iteration?userId=' + JSON.parse(sessionStorage.getItem('user_information')).userId + '&indicatorId=dataset5' + url);
    await axios.get(url, { headers: { 'Content-Type': 'application/json' } }).then((response) => {
      workQueueFilterResponse = response;
    });
  } catch (ex) {
    console.log('Error in get get-user-preference', ex.message);
  }

  return workQueueFilterResponse;
}

export async function updateDashboardCards(payload) {
  let workQueueFilterResponse = [];
  let user = JSON.parse(sessionStorage.getItem('user_information'));
  try {
    const url = process.env.REACT_APP_BACKEND_URL + `/buow_api/secroledashboardcardxref?contextId=${user.contextId}&cardId=${payload.cardId}&role=${user.rolesName}`;
    console.log('update-user-preference?userId=' + JSON.parse(sessionStorage.getItem('user_information')).userId + ' updating work queue: Calling ' + url);
    await axios.put(url, payload, { headers: { 'Content-Type': 'application/json' } }).then((response) => {
      workQueueFilterResponse = response;
    });
  } catch (ex) {
    console.log('Error in get get-user-preference', ex.message);
  }
  return workQueueFilterResponse;
}

export async function removeDashboardCards(payload) {
  let workQueueFilterResponse = [];
  let user = JSON.parse(sessionStorage.getItem('user_information'));
  try {
    const url = process.env.REACT_APP_BACKEND_URL + `/buow_api/secroledashboardcardxref?contextId=${user.contextId}&cardId=${payload.cardId}&role=${user.rolesName}`;
    console.log('update-user-preference?userId=' + JSON.parse(sessionStorage.getItem('user_information')).userId + ' updating work queue: Calling ' + url);
    await axios.delete(url, payload, { headers: { 'Content-Type': 'application/json' } }).then((response) => {
      workQueueFilterResponse = response;
    });
  } catch (ex) {
    console.log('Error in get get-user-preference', ex.message);
  }
  return workQueueFilterResponse;
}
