import React from 'react';
import crossIcon from '../../../../fsa-style/img/crossIcon.svg';
import CircleIcon from '../../../../fsa-style/img/circle-124.png';
import CorrectIcon from '../../../../fsa-style/img/correct8.svg';
import { Modal } from 'react-bootstrap';
import styles from './ConfirmationPopup.module.scss';
import CustomButton from '../../../../newcomponents/CustomButton/CustomButton';
import PropTypes from 'prop-types';

export const ConfirmationPopup = (props) => {
  return (
    <Modal
      {...props.props}
      show={props.show}
      size="md"
      data-testid="modal-container"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className={styles.deleteContainer}
      onHide={props.onHide}
      style={{ zIndex: 999999 }}
    >
      <div className={styles.confirmation_modal_popup}>
        <Modal.Header>
          <div className={styles.modal_header_section}>
            <div>
              <h2 className={styles.modal_header_panel_text}>Confirm Your Action</h2>
            </div>
            <div className={styles.modal_header_panel_icon} onClick={props.onHide}>
              <img data-testid="modalClose" src={crossIcon} alt="cross icon" />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className={styles.flex_container_custom}>
            <div className={styles.my_div}>
              <img src={CircleIcon} className={styles.confirmation_modal_delete_icon} alt="cross icon" />
              <img src={CorrectIcon} className={styles.correctIcon} alt="cross icon" />
            </div>
            <div className={styles.confirmation_modal_text_wrapper}>
              <span className={styles.confirmation_modal_heading}>Are you sure?</span>
              <div data-testid="deletionMessage" className={styles.confirmation_modal_text}>
                Do you really want to {props.popupTitle.toLowerCase()}? <br />
                click ok button to confirm
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className={styles.modal_footer_section}>
            <CustomButton
              title="Ok"
              className={styles.modal_footer_delete_button}
              onClick={() => {
                props.ConfirmDataFun();
              }}
            />
            <CustomButton data-testid="modalCancelBtn" title="Cancel" className={styles.modal_footer_cancel_button} onClick={props.onHide} />
          </div>
        </Modal.Footer>
      </div>
    </Modal>
  );
};

ConfirmationPopup.propTypes = {
  /**
   * Props to be passed to the underlying Modal component.
   */
  props: PropTypes.object.isRequired,

  /**
   * Flag to control the visibility of the modal.
   */
  show: PropTypes.bool.isRequired,

  /**
   * Function to be called when the modal is hidden.
   */
  onHide: PropTypes.func.isRequired,

  /**
   * Function to be called when the delete button is clicked.
   */
  DeleteDataFunc: PropTypes.func.isRequired,

  /**
   * Data related to the selected row to be deleted.
   */
  selectedDeleteRowData: PropTypes.any.isRequired,
};