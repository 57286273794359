//When we do for DRMS, we would look at the data type that comes from schema api to determine type of search
//In back end should probably make greater than do max time and less than do max time
export function createSearchObject(searchParamsObjArray, sort, docType, state, county, buowSearch) {
  let system_config = JSON.parse(window.localStorage.getItem('system_config'))?.buowDescription;
  const defaultBUOW = Object.keys(system_config).find((key) => system_config[key]?.is_default === true);

  let systemFolders = JSON.parse(sessionStorage.getItem('SystemColumns'));
  // Filter systemFolders to keep only those that match the selectedBuow
  const effectiveBuow = buowSearch || defaultBUOW; // Use defaultBUOW if selectedBuow is empty

  systemFolders = systemFolders
    .filter((itm) => itm.lowCard === false) // Keep only those with lowCard as false
    // .filter((itm) => itm.buowName == effectiveBuow); // Keep only those with matching buowName

  let metadataSearchObject = {
    searchCriteria: [],
    sort: sort,
    aggregation: [],
  };
  // code to push aggregated fields into search query which has lowCard value as false.
  systemFolders.map((itm) => {
    metadataSearchObject.aggregation.push('object_specific.' + itm.name);
  });
  searchParamsObjArray.forEach((param) => {
    let condition;
    //For DRMS, we would check if data type is boolean, do EQUALS. Else use CONTAINS
    if (param.key === 'is_reviewed' || param.key === 'is_corrected' || param.key === 'internal_review_required') {
      condition = 'EQUALS';
    } else {
      condition = 'CONTAINS';
    }
    if (param.key === 'Producer Identified') {
      metadataSearchObject.searchCriteria.push({
        condition: condition,
        field1: {
          name: '*core_customer_id',
          value: [param.core_customer_id],
        },
      });
    } else {
      metadataSearchObject.searchCriteria.push({
        condition: param.value === '' ? 'NOT_EXISTS' : condition,
        field1: {
          name: param.key,
          value: [param.value],
        },
      });
    }
  });

  if (docType && docType.length !== 0) {
    metadataSearchObject.searchCriteria.push({
      condition: 'EQUALS',
      field1: {
        name: 'object_type.object_type_name',
        value: [docType.objectTypeName],
      },
    });
  }

  if (state && state.length !== 0) {
    metadataSearchObject.searchCriteria.push({
      condition: 'EQUALS',
      field1: {
        name: 'object_specific.state_name',
        value: [state.name],
      },
    });
  }

  if (county && county.length !== 0) {
    metadataSearchObject.searchCriteria.push({
      condition: 'EQUALS',
      field1: {
        name: 'object_specific.county_name',
        value: [county.name],
      },
    });
  }

  if (sort !== undefined && sort.length > 0) {
    metadataSearchObject.sort = {
      fieldName: sort[0].field,
      order: sort[0].dir.toUpperCase(),
    };
  }
  metadataSearchObject.searchCriteria.push({
    condition: 'EQUALS',
    field1: {
      name: 'doc_repo.is_latest',
      value: [true],
    },
  });
  // console.log(metadataSearchObject);
  return metadataSearchObject;
}
