import React, { useEffect, useState, lazy } from 'react';
import { Modal } from 'react-bootstrap';
// import './SystemConfiguration.scss';
import styles from './SystemConfiguration.module.scss';
import '../../../GlobalStyle.scss';
import { Switch } from '@progress/kendo-react-inputs';
import TableSystemConfiguration from './TableSystemConfiguration';
import SystemConfigurationData from './SystemConfiguration.json';

import crossIcon from '../../../fsa-style/img/crossIcon.svg';
import SuccessProcessGrowl from '../../../components/SuspenseQueue/SuccessProcessGrowl';
// import InitialData from './SystemConfigData.json';
import { GlobalLoader } from '../../../newcomponents/GlobalLoader/GlobalLoader.js';
import Breadcrumb from '../../../newcomponents/Breadcrumb/Breadcrumb';
import { createSystemConfig, deleteSystemConfig, searchSystemConfig, updateSystemConfig } from './SystemConfigurationService';
import { DeleteModal } from '../../../newcomponents/DeletePopup/DeleteModal';
import ErrorGrowl from '../../../components/SuspenseQueue/ErrorGrowl';
import CustomButton from '../../../newcomponents/CustomButton/CustomButton';
import SearchAdminPanelComponent from '../../../newcomponents/SearchAdminPanelComponent/SearchAdminPanelComponent';
import { handleIsRequiredError, handleMaxLimitError } from '../../../utils/helper/handleReuiredErrorFunc';
import EditImg from '../../../fsa-style/img/svgs/Edit.svg';
import DeleteImg from '../../../fsa-style/img/svgs/Delete.svg';
import CrossImg from '../../../fsa-style/img/svgs/Cross.svg';
import RightImg from '../../../fsa-style/img/svgs/RightTick.svg';
import arrowImg from '../../../fsa-style/img/svgs/Vector.svg';
import { useRef } from 'react';
import { EventEmitter } from '../../../services/events';
import VisibilityControlData from './VisibilityControlData.json';
import AsyncSelect from 'react-select/async';
import '../../../GlobalStyle.scss';
import { removeExtraSpace } from '../../../utils/helper/globalFunc.js';
var _ = require('lodash');

const breadcrumbData = [
  { title: 'Administrator Panel', link: '/admin' },
  { title: 'System Configuration', link: '' },
];

// const TableSystemConfiguration = lazy(() => import('./TableSystemConfiguration'));
// const SuccessProcessGrowl = lazy(() => import('../../../components/SuspenseQueue/SuccessProcessGrowl'));
// const Breadcrumb = lazy(() => import('../../../newcomponents/Breadcrumb/Breadcrumb'));
// const ErrorGrowl = lazy(() => import('../../../components/SuspenseQueue/ErrorGrowl'));
// const CustomButton = lazy(() => import('../../../newcomponents/CustomButton/CustomButton'));
// const SearchAdminPanelComponent = lazy(() => import('../../../newcomponents/SearchAdminPanelComponent/SearchAdminPanelComponent'));

export default function SystemConfiguration() {
  // const [stepError, setStepError] = useState('');
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [recordModalShow, setRecordModalShow] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [deleteData, setDeleteData] = useState();
  const [isNew, setIsNew] = useState(false);
  const [message, setMessage] = useState();
  const [isSuccessGrowlHidden, setIsSuccessGrowlHidden] = useState(true);
  const [isLoading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    contextId: '',
    logo: '',
    title: '',
    clipBoardName: '',
    name: '',
    ruleViewSetting: '',
    id: '',
    buowDescription: null,
    adminPanel: null,
    features: null,
    drimsVersion: null,
  });

  const [searchText, setSearchText] = useState('');
  const [isErrorGrowlHidden, setIsErrorGrowlHidden] = useState(true);
  const [sorting, setSorting] = useState({ column: '', order: '' });

  const [systemConfigurationDetails, setSystemConfigurationData] = useState(SystemConfigurationData);
  const [inputDisabled, setInputDisabled] = useState(true);

  async function fetchSystemConfigData() {
    let payload = {
      text: searchText,
      column: sorting.column,
      order: sorting.order,
    };

    await searchSystemConfig(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  }

  useEffect(() => {
    setLoading(true);
    // fetchSystemConfigData();
    // getLocalStorageDataFunc()
  }, []);

  const onRemoveSort = async () => {
    setLoading(true);
    let payload = {
      text: searchText,
      column: '',
      order: '',
    };
    setSorting({ column: '', order: '' });

    await searchSystemConfig(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  };

  const onPageChange = ({ page }) => {
    setPageSize(page.take);
    setPage(page.skip / page.take);
  };

  const createNewFunc = () => {
    setFormData({
      contextId: '',
      logo: '',
      title: '',
      clipBoardName: '',
      ruleViewSetting: '',
      buowDescription: null,
      adminPanel: VisibilityControlData.adminPanel,
      features: VisibilityControlData.features,
      drimsVersion: null,
    });

    setIsNew(true);
    setRecordModalShow(true);
  };

  const DeleteDataFunc = (DeleteData) => {
    setLoading(true);
    const sendData = {
      id: DeleteData.id,
    };

    deleteSystemConfig(sendData).then(async (response) => {
      if (response.data) {
        setDeleteModalShow(false);
        await fetchSystemConfigData();
        setLoading(false);
        setIsSuccessGrowlHidden(false);
        setMessage('System Configuration deleted successfully');
        setTimeout(() => {
          setIsSuccessGrowlHidden(true);
        }, 3000);
      } else if (response.error) {
        setIsErrorGrowlHidden(false);
        setMessage(response.error.message ? response.error.message : 'Something went wrong');

        setTimeout(() => {
          setIsErrorGrowlHidden(true);
        }, 3000);
        console.log('error');
        setLoading(false);
      }
    });
  };

  const editFunc = (data) => {
    setFormData({
      contextId: data.contextId,
      logo: data.logo,
      title: data.title,
      clipBoardName: data.clipBoardName,
      ruleViewSetting: data.ruleViewSetting,
      id: data.id,
      buowDescription: data.buowDescription,
      adminPanel: data.adminPanel,
      features: data.features,
      drimsVersion: data.drimsVersion,
    });

    setIsNew(false);
    setRecordModalShow(true);
  };

  const DeleteFunc = (DeleteData) => {
    setDeleteModalShow(true);
    setDeleteData(DeleteData);
  };

  // const handleSearch = (e) => {
  //   const { value } = e.target;
  //   setSearchText(value.trimStart());
  //   if (value.length > 2) onSearch(value);
  //   if (value.length === 0) onSearch(value);
  // };

  const handleSearch = async (e) => {
    const { value } = e.target;
    setSearchText(value.trimStart());
  };

  useEffect(() => {
    if (searchText.length > 2) {
      const Timer = setTimeout(() => {
        onSearch(searchText);
      }, 2000);
      return () => clearTimeout(Timer);
    }
    if (searchText.length === 0) {
      onSearch(searchText);
    }
  }, [searchText]);

  const onSearch = async (searchText) => {
    setLoading(true);
    setInputDisabled(true);
    let payload = {
      text: searchText,
      column: sorting.column,
      order: sorting.order,
    };

    await searchSystemConfig(payload).then((response) => {
      setData(response.data);
      setInputDisabled(false);
      setPage(0);
      setLoading(false);
    });
  };

  const clearSearch = async () => {
    setLoading(true);
    setSearchText('');
    setSorting({ column: '', order: '' });
    setPage(0);
    setLoading(false);
    // Clear sort from table header
    let newSystemConfigurationData = JSON.parse(JSON.stringify(systemConfigurationDetails));
    newSystemConfigurationData.forEach((itm) => {
      itm.sortType = '';
    });
    setSystemConfigurationData(newSystemConfigurationData);
    await fetchNewSystemConfigData();
  };

  // Code needs to change
  const fetchNewSystemConfigData = async () => {
    let payload = {
      text: '',
      column: '',
      order: '',
    };
    await searchSystemConfig(payload).then((response) => {
      setData(response.data);
      setPage(0);
      setLoading(false);
    });
  };

  const onSortData = async (selectedColumn) => {
    setLoading(true);
    let sortObj = {
      column: selectedColumn.field,
      order: selectedColumn.sortType,
    };

    setSorting(sortObj);
    let payload = {
      column: selectedColumn.field,
      order: selectedColumn.sortType,
      text: searchText,
    };
    await searchSystemConfig(payload).then((response) => {
      setData(response.data);
      setLoading(false);
    });
  };

  return (
    <div className={styles.systemConfigurationPage}>
      {isLoading && <GlobalLoader />}

      <Breadcrumb data={breadcrumbData} />
      <div className={styles.systemConfigurationPageContent}>
        <h1 data-testid="page-heading" className={styles.pageTitle}>
          System Configuration
        </h1>
        <div>
          <div className={styles.searchSection}>
            <div data-testid="keyword-label" className={styles.keywordSearchTitle}>
              Keyword Search
            </div>
            <div className={styles.searchOuterContainer}>
              <div className="d-flex">
                <SearchAdminPanelComponent
                  searchText={searchText}
                  handleSearch={handleSearch}
                  onSearch={onSearch}
                  clearSearch={clearSearch}
                  inputDisabled={inputDisabled}
                  testId={'system-config-search'}
                ></SearchAdminPanelComponent>
              </div>
              <div>
                <CustomButton title="Create System Configuration" testId={'create-test-systemConfig'} className={styles.createRecordBtnStyle} onClick={createNewFunc} />
              </div>
            </div>
            <span className="searchNoteStyle"> Note: Entering 3 letters enables search capabilities.</span>
          </div>
        </div>
        <div className={[styles.systemConfigurationTable, 'glbSystemConfigurationTable'].join(' ')}>
          <TableSystemConfiguration
            data={data}
            page={page}
            editFunc={editFunc}
            DeleteFunc={DeleteFunc}
            pageSize={pageSize}
            fields={systemConfigurationDetails}
            onPageChange={onPageChange}
            searchKeyword={searchText}
            setRecordModalShow={setRecordModalShow}
            setDeleteModalShow={setDeleteModalShow}
            onSortData={onSortData}
            onRemoveSort={onRemoveSort}
          />
        </div>
        <CreateEditSystemConfig
          isNew={isNew}
          show={recordModalShow}
          formData={formData}
          setIsSuccessGrowlHidden={setIsSuccessGrowlHidden}
          setIsErrorGrowlHidden={setIsErrorGrowlHidden}
          setMessage={setMessage}
          setFormData={setFormData}
          setRecordModalShow={setRecordModalShow}
          onHide={() => setRecordModalShow(false)}
          isLoading={(e) => setLoading(e)}
          fetchSystemConfigData={fetchSystemConfigData}
        />
        {deleteModalShow && (
          <DeleteModal
            show={deleteModalShow}
            DeleteDataFunc={DeleteDataFunc}
            onHide={() => setDeleteModalShow(false)}
            setDeleteModalShow={setDeleteModalShow}
            selectedDeleteRowData={deleteData}
            setIsSuccessGrowlHidden={setIsSuccessGrowlHidden}
            setMessage={setMessage}
            popupTitle={'System Configuration'}
          ></DeleteModal>
        )}
        <ErrorGrowl isErrorGrowlHidden={isErrorGrowlHidden} setIsErrorGrowlHidden={setIsErrorGrowlHidden} message={message} />
        <SuccessProcessGrowl isSuccessGrowlHidden={isSuccessGrowlHidden} setIsSuccessGrowlHidden={setIsSuccessGrowlHidden} message={message} />
      </div>
      {/* <Footer /> */}
    </div>
  );
}

// START:- Create / Edit record modal with functionality
const CreateEditSystemConfig = (props) => {
  const [selectedImg, setSelectedImg] = useState(null);
  const [selectedImgFile, setSelectedImgFile] = useState(null);
  const [inputBuowInput, setinputBuowInput] = useState('');
  const [selectedBuowField, setSelectedBuowField] = useState('');
  const [selectedAliasField, setSelectedAliasField] = useState('');
  const [selectedAliasFieldKey, setSelectedAliasFieldKey] = useState('');
  const [isRequiredError, setisRequiredError] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isEditAlias, setIsEditAlias] = useState(false);
  const [system_config, setSystem_config] = useState({ buowDescription: null });
  const [indexSheetAliasKeys, setIndexSheetAliasKeys] = useState([]);
  const [indexSheetAliasValues, setIndexSheetAliasValues] = useState([]);
  const [indexSheetAliasBuow, setIndexSheetAliasBuow] = useState([]);
  const [selectedNavPill, setSelectedNavPill] = useState('BUOW');
  const [fieldIP, setFieldIP] = useState('');
  const [aliasFieldIP, setAliasFieldIP] = useState('');
  const [deleteBuowModalShow, setDeleteBuowModalShow] = useState(false);
  const [deleteBuowField, setDeleteBuowField] = useState('');
  const [deleteAliasField, setDeleteAliasField] = useState('');
  const [deleteTitle, setDeleteTitle] = useState('');
  const inputRef = useRef(null);
  const selectRef = useRef(null);
  const inputBuowRef = useRef(null);
  const [makeItDefaultModalShow, setMakeItDefaultModalShow] = useState(false);
  const [seqArr, setSeqArr] = useState([]);
  const inputAliasRef = useRef(null);
  const [toggleButtons, setToggleButtons] = useState([
    {
      title: 'Index Sheet',
      value: false,
    },
    {
      title: 'Separator Sheet',
      value: false,
    },
    {
      title: 'Make it Default',
      value: system_config?.buowDescription !== null && Object.keys(system_config?.buowDescription).length > 1 ? false : true,
    },
  ]);
  const [selectType, setSelectType] = useState('text');
  const [selectRequired, setSelectRequired] = useState(true);
  const [selectEditType, setSelectEditType] = useState('');
  const [selectEditRequire, setSelectEditRequire] = useState(true);

  const [indexSheetToggle, setIndexSheetToggle] = useState(false);
  const [separatorToggle, setSeparatorToggle] = useState(false);
  const [isDefaultToggle, setIsDefaultToggle] = useState(false);

  const buowType = [
    { value: '', label: 'Select File Type' },
    { value: 'Document', label: 'Document' },
    { value: 'Form', label: 'Form' },
    { value: 'File Review', label: 'File Review' },
    { value: 'Envelope Review', label: 'Envelope Review' },
  ];
  const httpMethodType = [
    { value: '', label: 'Select HTTP Method' },

    { value: 'POST', label: 'POST' },
    { value: 'PATCH', label: 'PATCH' },
  ];
  const authType = [
    { value: '', label: 'Select Authentication Type' },
    { value: 'None', label: 'None' },
    { value: 'Basic', label: 'Basic' },
    { value: 'OAuth2', label: 'OAuth2' },
  ];
  const [buowTypeValue, setBuowTypeValue] = useState('');
  const [buowSubject, setBuowSubject] = useState('');
  const [buowTypeReq, setBuowTypeReq] = useState(false);
  const [externalServiceToggle, setExternalServiceToggle] = useState(false);
  const [endPoint, setEndPoint] = useState('');
  const [url, setUrl] = useState('');
  const [httpMethod, setHttpMethod] = useState('');
  const [headers, setHeaders] = useState('');
  const [queryParams, setQueryParams] = useState('');
  const [authenticationType, setAuthenticationType] = useState('');
  const [bodyParams, setBodyParams] = useState('');
  const [description, setDescription] = useState('');
  const [notes, setNotes] = useState('');
  const [apiObject, setApiObject] = useState(null);
  const [endPointReq, setEndPointReq] = useState(false);
  const [urlReq, setUrlReq] = useState(false);
  const [httpReq, setHttpReq] = useState(false);
  const [invalidUrl, setInvalidUrl] = useState(false);
  const externalServiceTextHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (name === 'endPointName') {
      if (value.trim() === '') {
        setEndPointReq(true);
        setEndPoint('');
      } else {
        setEndPointReq(false);
        setEndPoint(value);
      }
    }
    if (name === 'url') {
      if (value.trim() === '') {
        setUrlReq(true);
        setUrl('');
      } else {
        setUrlReq(false);
        if (urlValidator(value)) {
          setInvalidUrl(false);
        } else {
          setInvalidUrl(true);
        }
        setUrl(value);
      }
    }
    if (name === 'headers') {
      setHeaders(value);
    }
    if (name === 'queryParams') {
      setQueryParams(value);
    }
    if (name === 'bodyParams') {
      setBodyParams(value);
    }
    if (name === 'description') {
      setDescription(value);
    }
    if (name === 'notes') {
      setNotes(value);
    }
    // setEndPoint(e)
  };

  const externalServiceHttpDropdownHandler = (e) => {
    if (e) {
      if (e.value.trim() === '') {
        setHttpReq(true);
        setHttpMethod('');
      } else {
        setHttpReq(false);
        setHttpMethod(e.value);
      }
    }
  };
  const authTypeFilter = (inputValue) => {
    return authType.filter((i) => i.label.toLowerCase().includes(inputValue.toLowerCase()));
  };
  const httpMethodTypeFilter = (inputValue) => {
    return httpMethodType.filter((i) => i.label.toLowerCase().includes(inputValue.toLowerCase()));
  };
  const externalServiceAuthTypeDropdownHandler = (e) => {
    if (e) {
      setAuthenticationType(e.value);
    }
  };

  // "http://example.com"
  // "https://www.example.com"
  // "www.example.com"
  // "example.com"
  const urlValidator = (URL) => {
    const urlPattern = /\b((http|https):\/\/)?(www\.)?([a-zA-Z0-9-]{1,63}\.)+[a-zA-Z]{2,6}(\/[a-zA-Z0-9#:%_+.~?&//=]*)?\b/;

    // Test the provided string against the regex
    return urlPattern.test(URL);
  };

  const clearExternalApiStates = () => {
    setEndPoint('');
    setUrl('');
    setHttpMethod('');
    setHeaders('');
    setQueryParams('');
    setAuthenticationType('');
    setBodyParams('');
    setDescription('');
    setNotes('');
    setApiObject(null);
    setEndPointReq(false);
    setHttpReq(false);
    setUrlReq(false);
  };

  const customStyles = {
    control: (base) => ({
      ...base,
      height: 32,
      minHeight: 32,
      width: '33rem',
    }),
    menu: (base) => ({
      ...base,
      // width: '222px',
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: 32,
      width: 32,
      position: 'initial',
      padding: '0px 8px',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: 32,
    }),
    input: (provided, state) => ({
      ...provided,
      height: 32,
      padding: 0,
      margin: 0,
    }),
    container: (provided, state) => ({
      ...provided,
      // minWidth: '222px',
    }),
  };
  const customStylesHttp = {
    control: (base) => ({
      ...base,
      height: 32,
      minHeight: 32,
      width: '100%',
    }),
    menu: (base) => ({
      ...base,
      width: '33rem',
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: 32,
      width: 32,
      position: 'initial',
      padding: '0px 8px',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: 32,
    }),
    input: (provided, state) => ({
      ...provided,
      height: 32,
      padding: 0,
      margin: 0,
    }),
    container: (provided, state) => ({
      ...provided,
      minWidth: '100%',
    }),
  };
  const customStylesAuth = {
    control: (base) => ({
      ...base,
      height: 32,
      minHeight: 32,
      width: '100%',
    }),
    menu: (base) => ({
      ...base,
      width: '33rem',
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: 32,
      width: 32,
      position: 'initial',
      padding: '0px 8px',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: 32,
    }),
    input: (provided, state) => ({
      ...provided,
      height: 32,
      padding: 0,
      margin: 0,
    }),
    container: (provided, state) => ({
      ...provided,
      minWidth: '100%',
      // minWidth: '222px',
    }),
  };

  const clearAlias = () => {
    setIndexSheetAliasBuow([]);
    setIndexSheetAliasKeys([]);
    setSelectedAliasField('');
    setSelectedAliasFieldKey('');
    setSelectedBuowField('');
    setApiObject(null);
    setFieldIP('');
    setAliasFieldIP('');
    setIsEdit(false);
    setIsEditAlias(false);
    setDeleteBuowField('');
    setDeleteAliasField('');
    setDeleteTitle('');
    setSelectedNavPill('BUOW');
    setBuowTypeValue('');
    setBuowSubject('');
    setSelectType('text');
    setSelectRequired(true);
    setExternalServiceToggle(false);
    clearExternalApiStates();
  };

  const dragItm = useRef(0);
  const dragOverItm = useRef(0);

  useEffect(() => {
    setisRequiredError(false);
    if (props.isNew === true && selectedImgFile === null) setSelectedImg(null);
  }, [props.formData, props.isNew, selectedImgFile]);
  useEffect(() => {
    setSystem_config({ buowDescription: props.formData.buowDescription });
  }, [props.formData]);
  useEffect(() => {
    //sets array for the buowDescription keys

    if (system_config?.buowDescription !== null) {
      setIndexSheetAliasBuow(Object.keys(system_config?.buowDescription));
    } else {
      setIndexSheetAliasBuow([]);
    }
    //sets array for the indexSheetAlias keys
    if (selectedBuowField !== '') {
      let clonedIdx = system_config?.buowDescription[selectedBuowField]?.indexSheet;
      setIndexSheetAliasKeys(Object.keys(clonedIdx).sort((a, b) => clonedIdx[a].sequence - clonedIdx[b].sequence));
      //sets array for the indexSheetAlias values

      if (selectedAliasField !== '') {
        setIndexSheetAliasValues(system_config?.buowDescription[selectedBuowField]?.indexSheet[selectedAliasField]?.alias);
      }
    }
    // if (props.formData.buowDescription === null && system_config !== null) {
    props.formData.buowDescription = system_config.buowDescription;
    // }
  }, [system_config, selectedBuowField, selectedAliasField]);

  useEffect(() => {
    if (props.show === false) {
      clearAlias();
      setSystem_config({ buowDescription: null });
    }
  }, [props.show]);

  useEffect(() => {
    if (selectedBuowField !== '' && system_config?.buowDescription[selectedBuowField]?.external_api_details !== null) {
      if (system_config?.buowDescription[selectedBuowField]?.external_api_details?.endpointName?.trim() === '') {
        setEndPointReq(true);
      } else {
        setEndPoint(system_config?.buowDescription[selectedBuowField]?.external_api_details?.endpointName);
      }
      if (system_config?.buowDescription[selectedBuowField]?.external_api_details?.url?.trim() === '') {
        setUrlReq(true);
      } else {
        setUrl(system_config?.buowDescription[selectedBuowField]?.external_api_details?.url);
      }

      if (system_config?.buowDescription[selectedBuowField]?.external_api_details?.httpMethod?.trim() === '') {
        setHttpReq(true);
      } else {
        setHttpMethod(system_config?.buowDescription[selectedBuowField]?.external_api_details?.httpMethod);
      }

      setHeaders(system_config?.buowDescription[selectedBuowField]?.external_api_details?.headers);
      setQueryParams(system_config?.buowDescription[selectedBuowField]?.external_api_details?.queryParams);
      setAuthenticationType(system_config?.buowDescription[selectedBuowField]?.external_api_details?.authenticationType);
      setBodyParams(system_config?.buowDescription[selectedBuowField]?.external_api_details?.bodyParams);
      setDescription(system_config?.buowDescription[selectedBuowField]?.external_api_details?.description);
      setNotes(system_config?.buowDescription[selectedBuowField]?.external_api_details?.notes);
      setExternalServiceToggle(
        system_config?.buowDescription[selectedBuowField]?.is_external_service_enabled !== undefined
          ? system_config?.buowDescription[selectedBuowField]?.is_external_service_enabled
          : false
      );
      // notes: notes,
    }
  }, [selectedBuowField]);
  useEffect(() => {
    if (selectedBuowField !== '') {
      setSystem_config((values) => ({
        ...values,
        buowDescription: {
          ...values.buowDescription,
          [selectedBuowField]: {
            ...values.buowDescription[selectedBuowField],
            index_sheet_workflow: indexSheetToggle,

            separator_sheet: separatorToggle,
            is_external_service_enabled: externalServiceToggle,
          },
        },
      }));
    }
  }, [indexSheetToggle, separatorToggle, isDefaultToggle, externalServiceToggle]);
  useEffect(() => {
    if (buowTypeValue !== '') {
      // system_config?.buowDescription[selectedBuowField]?.indexSheet.buow_type = buowTypeValue
      setSystem_config((values) => ({
        ...values,
        buowDescription: {
          ...values.buowDescription,
          [selectedBuowField]: {
            ...values.buowDescription[selectedBuowField],
            buow_type: buowTypeValue,
          },
        },
      }));
    }
    //added condition to prevent app crashing on first render of component
    if (system_config?.buowDescription !== null) {
      setSystem_config((values) => ({
        ...values,
        buowDescription: {
          ...values.buowDescription,
          [selectedBuowField]: {
            ...values.buowDescription[selectedBuowField],
            buow_subject: buowSubject,
          },
        },
      }));
    }
  }, [buowTypeValue, buowSubject]);

  useEffect(() => {
    setApiObject((values) => ({
      ...values,
      endpointName: endPoint,
      url: url,
      httpMethod: httpMethod,
      headers: headers,
      queryParams: queryParams,
      authenticationType: authenticationType,
      bodyParams: bodyParams,
      description: description,
      notes: notes,
    }));
  }, [endPoint, url, httpMethod, headers, queryParams, authenticationType, bodyParams, description, notes]);

  useEffect(() => {
    if (apiObject !== null && selectedBuowField !== '') {
      if (apiObject?.endpointName?.trim() === '') {
        setEndPointReq(true);
      } else {
        setEndPointReq(false);
      }
      if (apiObject?.url?.trim() === '') {
        setUrlReq(true);
      } else {
        setUrlReq(false);
      }
      if (apiObject?.httpMethod?.trim() === '') {
        setHttpReq(true);
      } else {
        setHttpReq(false);
      }
      setSystem_config((values) => ({
        ...values,
        buowDescription: {
          ...values.buowDescription,
          [selectedBuowField]: {
            ...values.buowDescription[selectedBuowField],
            external_api_details: apiObject,
          },
        },
      }));
    }
  }, [apiObject]);
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    props.setFormData((prevData) => ({
      ...prevData,
      [name]: value.trimStart(),
    }));
  };
  const handleBuowTypeChange = (selectedOption) => {
    if (selectedOption) {
      setBuowTypeValue(selectedOption.value);
      setBuowTypeReq(false);
    } else {
      setBuowTypeValue('');
    }
  };
  const handleBuowSubject = (selectedOption) => {
    let value = selectedOption.target.value;
    if (value) {
      setBuowSubject(value);
    } else {
      setBuowSubject('');
    }
  };

  const handleToggleChange = (event, index, checkSection, title) => {
    const value = event.target.value;
    // const booleanValue = value === 'true';
    let updatedValue;
    if (checkSection === 'buowToggle') {
      if (title === 'Make it Default') {
        setMakeItDefaultModalShow(true);
      } else {
        const updatedButtons = [...toggleButtons];
        updatedButtons[index].value = value;
        setToggleButtons(updatedButtons);
      }
    }

    if (checkSection === 'adminPanel') {
      updatedValue = _.cloneDeep(props.formData?.adminPanel);
    } else {
      updatedValue = _.cloneDeep(props.formData?.features);
    }

    updatedValue = updatedValue?.map((item, idx) => {
      if (index === idx) {
        return { ...item, value: value }; // Spread item first, then add value property
      } else {
        return { ...item }; // If index doesn't match, return the item unchanged
      }
    });

    if (checkSection === 'adminPanel') {
      props.setFormData((prevData) => ({
        ...prevData,
        adminPanel: updatedValue,
      }));
    } else {
      props.setFormData((prevData) => ({
        ...prevData,
        features: updatedValue,
      }));
    }
  };

  const handleToggleIndex = (event, title) => {
    const value = event.target.value;
    if (title === 'index') {
      setIndexSheetToggle(value);
    }
    if (title === 'separator') {
      setSeparatorToggle(value);
    }
    if (title === 'default') {
      // setIsDefaultToggle(value)
      setMakeItDefaultModalShow(true);
    }
  };
  const handleSelectType = (e, section) => {
    if (section === 'add') {
      setSelectType(e.target.value);
    }
    if (section === 'edit') {
      setSelectEditType(e.target.value);
    }
    if (section === 'require') {
      setSelectRequired(JSON.parse(e.target.value));
    }
    if (section === 'requireEdit') {
      setSelectEditRequire(JSON.parse(e.target.value));
    }
  };

  const handleIcon = (e) => {
    const data = new FileReader();
    data.addEventListener('load', () => {
      props.setFormData((prevData) => ({
        ...prevData,
        logo: '',
      }));
      setSelectedImg(data.result);
    });
    data.readAsDataURL(e.target.files[0]);
    setSelectedImgFile(e.target.files[0]);
  };

  const handleSelectChange = (event) => {
    const value = event.target.value;
    const name = event.target.name;
    props.setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    try {
      let errMapFlag = false;

      if (!props.formData.contextId || props.formData.contextId?.length > 50 || !props.formData.title || !selectedImg || indexSheetAliasBuow.length === 0) {
        setisRequiredError(true);
        return;
      }
      if (selectedBuowField !== '') {
        if (system_config?.buowDescription[selectedBuowField]?.buow_type === undefined || system_config?.buowDescription[selectedBuowField]?.buow_type === '') {
          setBuowTypeReq(true);
          errMapFlag = true;
          return;
        } else {
          indexSheetAliasBuow?.map((b) => {
            if (system_config?.buowDescription[b]?.buow_type === undefined || system_config?.buowDescription[b]?.buow_type === '') {
              errMapFlag = true;
              return;
            }
            return;
          });
        }
      } else {
        indexSheetAliasBuow?.map((b) => {
          if (system_config?.buowDescription[b]?.buow_type === undefined || system_config?.buowDescription[b]?.buow_type === '') {
            errMapFlag = true;
            return;
          }
          return;
        });
      }

      if (errMapFlag === true) {
        props.setIsErrorGrowlHidden(false);
        props.setMessage('Please select BUOW Type !');
        setTimeout(() => {
          props.setIsErrorGrowlHidden(true);
        }, 3000);
        return;
      }
      if (
        system_config?.buowDescription[selectedBuowField]?.is_external_service_enabled === true &&
        system_config?.buowDescription[selectedBuowField]?.external_api_details === null
      ) {
        setUrlReq(true);
        setHttpReq(true);
        setEndPointReq(true);
        return;
      }
      if (endPointReq || urlReq || httpReq || invalidUrl) return;

      let payload = _.cloneDeep(props.formData);

      props.isLoading(true);
      if (selectedImg) {
        let logoBlob = selectedImg.split(',').pop();
        if (logoBlob) payload.logo = logoBlob;
      }

      Object.assign(payload, { buowDescription: system_config.buowDescription });
      indexSheetAliasBuow?.forEach((b) => {
        payload.buowDescription[b].buow_subject = payload.buowDescription[b].buow_subject.trim();
      });

      await createSystemConfig(payload)
        .then((response) => {
          if (response.data) {
            props.fetchSystemConfigData();
            props.setRecordModalShow(false);
            props.setIsSuccessGrowlHidden(false);
            props.setMessage('System Configuration created successfully');
            setSelectedImg(null);

            setTimeout(() => {
              props.setIsSuccessGrowlHidden(true);
            }, 3000);
            props.isLoading(false);
            clearExternalApiStates();
          } else if (response.error) {
            props.setIsErrorGrowlHidden(false);
            props.setMessage(response.error.message ? response.error.message : 'Something went wrong');
            // setSelectedImg(null);

            setTimeout(() => {
              props.setIsErrorGrowlHidden(true);
            }, 3000);
            props.isLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const handleEdit = async () => {
    try {
      let errMapFlag = false;

      if ((!props.formData.contextId || props.formData.contextId?.length > 50 || !props.formData.title) && (!selectedImg || !props.formData.logo)) {
        setisRequiredError(true);
        return;
      }
      if (selectedBuowField !== '') {
        if (system_config?.buowDescription[selectedBuowField]?.buow_type === undefined || system_config?.buowDescription[selectedBuowField]?.buow_type === '') {
          setBuowTypeReq(true);
          errMapFlag = true;
          return;
        } else {
          indexSheetAliasBuow?.map((b) => {
            if (system_config?.buowDescription[b]?.buow_type === undefined || system_config?.buowDescription[b]?.buow_type === '') {
              errMapFlag = true;
              return;
            }
            return;
          });
        }
      } else {
        indexSheetAliasBuow?.map((b) => {
          if (system_config?.buowDescription[b]?.buow_type === undefined || system_config?.buowDescription[b]?.buow_type === '') {
            errMapFlag = true;
            return;
          }
          return;
        });
      }

      if (errMapFlag === true) {
        props.setIsErrorGrowlHidden(false);
        props.setMessage('Please select BUOW Type !');
        setTimeout(() => {
          props.setIsErrorGrowlHidden(true);
        }, 3000);
        return;
      }
      if (
        system_config?.buowDescription[selectedBuowField]?.is_external_service_enabled === true &&
        system_config?.buowDescription[selectedBuowField]?.external_api_details === null
      ) {
        setUrlReq(true);
        setHttpReq(true);
        setEndPointReq(true);
        return;
      }
      if (urlReq || httpReq || invalidUrl) return;
      // let payload = { ..._.cloneDeep(props.formData) };
      let payload = _.cloneDeep(props.formData);
      if (selectedImg) {
        let logoBlob = selectedImg.split(',').pop();
        if (logoBlob) payload.logo = logoBlob;
      }
      props.isLoading(true);

      Object.assign(payload, { buowDescription: system_config.buowDescription });
      indexSheetAliasBuow?.forEach((b) => {
        payload.buowDescription[b].buow_subject = payload.buowDescription[b].buow_subject.trim();
      });

      await updateSystemConfig(payload).then((response) => {
        if (response.data) {
          setSelectedAliasField('');
          setSelectedAliasFieldKey('');
          setSelectedBuowField('');
          setSystem_config({ buowDescription: response.data.buowDescription });
          props.setIsSuccessGrowlHidden(false);
          props.setMessage('System Configuration updated successfully');
          setSelectedImg(null);

          let systemConfig = JSON.parse(localStorage.getItem('system_config'));

          if (props?.formData?.contextId === systemConfig.contextId) {
            // Update only the 'features' property
            systemConfig = props.formData;
            // Save the modified data back to local storage
            localStorage.setItem('system_config', JSON.stringify(systemConfig));
          }

          EventEmitter.emit('LocalStorageUpdated');

          setTimeout(() => {
            props.setIsSuccessGrowlHidden(true);
          }, 3000);

          props.fetchSystemConfigData();
          props.setRecordModalShow(false);
          setSelectedImg(null);
        } else if (response.error) {
          props.setIsErrorGrowlHidden(false);
          props.setMessage(response.error.message ? response.error.message : 'Something went wrong');

          setTimeout(() => {
            props.setIsErrorGrowlHidden(true);
          }, 3000);
          props.isLoading(false);
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  const saveField = () => {
    let tempObj = system_config?.buowDescription[selectedBuowField].indexSheet;
    if (inputRef.current.value.trim() !== '') {
      if (inputRef.current.name === '') {
        Object.assign(tempObj, {
          [inputRef.current.value]: {
            alias: [],
            sequence: Object.keys(system_config?.buowDescription[selectedBuowField].indexSheet)?.length + 1,
            type: selectEditType,
            is_required: selectEditRequire,
          },
        });
      } else {
        tempObj[inputRef.current.name].type = selectEditType;

        tempObj[inputRef.current.name].is_required = selectEditRequire;
        Object.assign(tempObj, { [inputRef.current.value]: tempObj[inputRef.current.name] });
        if (inputRef.current.name !== inputRef.current.value) {
          delete tempObj[inputRef.current.name];
        }
      }
      setSystem_config((values) => ({
        ...values,
        buowDescription: {
          ...values.buowDescription,
          [selectedBuowField]: {
            ...values.buowDescription[selectedBuowField],
            indexSheet: tempObj,
          },
        },
      }));
      setIsEdit(false);
      setSelectEditType('');
      setSelectEditRequire(true);
    }
  };

  //adding the buow field to object from buow array
  const saveFieldBuow = () => {
    let tempObj = {};
    if (inputBuowRef.current.value.trim() !== '') {
      if (inputBuowRef.current.name === '') {
        tempObj = {
          [inputBuowRef.current.value]: {
            indexSheet: {},
            is_default: system_config?.buowDescription === null ? true : false,
            separator_sheet: true,
            index_sheet_workflow: true,
            external_api_details: null,
          },
        };
      } else {
        Object.assign(tempObj, { [inputBuowRef.current.value]: tempObj[inputBuowRef.current.name] });
        if (inputBuowRef.current.name !== inputBuowRef.current.value) {
          delete tempObj[inputBuowRef.current.name];
        }
      }
      setSystem_config((values) => ({
        ...values,
        buowDescription: {
          ...values.buowDescription,
          ...tempObj,
        },
      }));
      setinputBuowInput('');
      inputBuowRef.current.value = '';
    }
  };

  const saveFieldAlias = () => {
    let tempArr = [...system_config?.buowDescription[selectedBuowField].indexSheet[selectedAliasField]?.alias];
    if (inputAliasRef.current.value.trim() !== '') {
      if (inputAliasRef.current.name === '') {
        tempArr.push(inputAliasRef.current.value);
      } else {
        let index = tempArr.indexOf(inputAliasRef.current.name);
        tempArr[index] = inputAliasRef.current.value;
      }
      setSystem_config((values) => ({
        ...values,
        buowDescription: {
          ...values.buowDescription,
          [selectedBuowField]: {
            ...values.buowDescription[selectedBuowField],
            indexSheet: {
              ...values.buowDescription[selectedBuowField].indexSheet,
              [selectedAliasField]: {
                ...values.buowDescription[selectedBuowField].indexSheet[selectedAliasField],
                alias: tempArr,
              },
            },
          },
        },
      }));
    }
    // setSelectedAliasFieldKey('');
  };

  const deleteFields = () => {
    if (deleteBuowField !== '') {
      let tempObj = system_config?.buowDescription[selectedBuowField].indexSheet;

      delete tempObj[deleteBuowField];
      setSystem_config((values) => ({
        ...values,
        buowDescription: {
          ...values.buowDescription,
          [selectedBuowField]: {
            ...values.buowDescription[selectedBuowField],
            indexSheet: tempObj,
          },
        },
      }));
      if (Object.keys(system_config.buowDescription[selectedBuowField].indexSheet) !== undefined) {
        Object.keys(system_config.buowDescription[selectedBuowField].indexSheet).forEach((ele) => {
          if (system_config.buowDescription[selectedBuowField].indexSheet[ele].sequence === 1) {
            setSelectedAliasField(ele);
          }
        });
      } else {
        setSelectedAliasField('');
      }
      setDeleteBuowField('');
      setDeleteAliasField('');
      setDeleteBuowModalShow(false);
    }
    if (deleteAliasField !== '') {
      let tempArr = system_config?.buowDescription[selectedBuowField].indexSheet[selectedAliasField].alias;
      tempArr = tempArr.filter((item) => item !== deleteAliasField);
      setSystem_config((values) => ({
        ...values,
        buowDescription: {
          ...values.buowDescription,
          [selectedBuowField]: {
            ...values.buowDescription[selectedBuowField],
            indexSheet: {
              ...values.buowDescription[selectedBuowField].indexSheet,
              [selectedAliasField]: {
                ...values.buowDescription[selectedBuowField].indexSheet[selectedAliasField],
                alias: tempArr,
              },
            },
          },
        },
      }));
      setDeleteAliasField('');
      setDeleteBuowField('');
      setDeleteBuowModalShow(false);
      // setSelectedAliasFieldKey('');
    }
  };

  useEffect(() => {
    if (selectedBuowField !== '') {
      setSeparatorToggle(
        system_config?.buowDescription[selectedBuowField].separator_sheet !== undefined ? system_config?.buowDescription[selectedBuowField]?.separator_sheet : false
      );
      setIndexSheetToggle(
        system_config?.buowDescription[selectedBuowField].index_sheet_workflow !== undefined ? system_config?.buowDescription[selectedBuowField]?.index_sheet_workflow : false
      );
      if (Object.keys(system_config?.buowDescription).length > 1) {
        setIsDefaultToggle(system_config?.buowDescription[selectedBuowField].is_default !== undefined ? system_config?.buowDescription[selectedBuowField]?.is_default : false);
      } else {
        setIsDefaultToggle(true);
      }
    }
  }, [selectedBuowField]);

  const handleObj = (newArr) => {
    let newIndexObj = {};
    newArr.map((li) => {
      Object.keys(system_config?.buowDescription[selectedBuowField]?.indexSheet).map((objItm) => {
        if (li === objItm) {
          newIndexObj = { ...newIndexObj, [li]: system_config?.buowDescription[selectedBuowField]?.indexSheet[li] };
        }
      });
    });
    setSystem_config((values) => ({
      ...values,
      buowDescription: {
        ...values.buowDescription,
        [selectedBuowField]: {
          ...values.buowDescription[selectedBuowField],
          indexSheet: newIndexObj,
        },
      },
    }));
  };
  const handleSort = () => {
    const itemsClone = [...indexSheetAliasKeys];
    const tempObjHolder = itemsClone[dragItm.current];
    itemsClone[dragItm.current] = itemsClone[dragOverItm.current];
    itemsClone[dragOverItm.current] = tempObjHolder;
    setIndexSheetAliasKeys(itemsClone);
    setSeqArr(itemsClone);
    // handleObj(itemsClone);
  };
  const handleSelectEditVal = (val) => {
    setSelectEditType(val);
  };
  const renderFieldName = (item, idx) => {
    return (
      <li
        draggable
        onDragStart={() => {
          dragItm.current = idx;
        }}
        onDragEnter={() => {
          dragOverItm.current = idx;
        }}
        onDragEnd={() => {
          handleSort();
        }}
        onDragOver={(e) => {
          e.preventDefault();
        }}
        className={styles.indexListPill}
      >
        {selectedAliasField === item && isEdit === true ? (
          <>
            <input
              data-testid={`field-input-${item}`}
              ref={inputRef}
              className={styles.indexListInput}
              defaultValue={item}
              name={item}
              onClick={() => {
                setSelectedAliasField(item);
              }}
              placeholder={`${item === '' && 'Enter Field Name'}`}
            ></input>
            <select
              className={styles.textInputBuow}
              defaultValue={system_config?.buowDescription[selectedBuowField]?.indexSheet[item]?.type}
              name="types"
              onChange={(e) => {
                handleSelectType(e, 'edit');
              }}
            >
              <option value={'text'}>Text</option>
              <option value={'boolean'}>Boolean</option>
              <option value={'date'}>Date</option>
              <option value={'number'}>Number</option>
            </select>
            <select
              className={styles.textInputBuow}
              defaultValue={system_config?.buowDescription[selectedBuowField]?.indexSheet[item]?.is_required}
              name="required"
              onChange={(e) => {
                handleSelectType(e, 'requireEdit');
              }}
            >
              <option value={true}>Required</option>
              <option value={false}>Not Required</option>
            </select>
          </>
        ) : (
          <>
            <p
              data-testid={`buow-field-label-${item}`}
              className={`${styles.indexListLabel} ${selectedAliasField === item && styles.activePill}`}
              onClick={() => {
                setSelectedAliasField(item);
                setIsEdit(false);
              }}
            >
              {item === '' ? 'Enter Field Name' : item}
            </p>
            <select
              ref={selectRef}
              className={`${styles.textInputBuow} ${selectedAliasField === item && styles.activePill}`}
              defaultValue={system_config?.buowDescription[selectedBuowField]?.indexSheet[item]?.type}
              value={system_config?.buowDescription[selectedBuowField]?.indexSheet[item]?.type}
              name="types"
              disabled={true}
              onChange={(e) => {}}
            >
              <option value={'text'}>Text</option>
              <option value={'boolean'}>Boolean</option>
              <option value={'date'}>Date</option>
              <option value={'number'}>Number</option>
            </select>
            <select
              className={`${styles.textInputBuow} ${selectedAliasField === item && styles.activePill}`}
              defaultValue={system_config?.buowDescription[selectedBuowField]?.indexSheet[item]?.is_required}
              value={system_config?.buowDescription[selectedBuowField]?.indexSheet[item]?.is_required}
              name="required"
              onChange={(e) => {}}
              disabled={true}
            >
              <option value={true}>Required</option>
              <option value={false}>Not Required</option>
            </select>
          </>
        )}
        {selectedAliasField === item && isEdit === true ? (
          <>
            <img
              alt="save"
              className={styles.actionIcnEdit}
              src={RightImg}
              onClick={(e) => {
                saveField(inputRef);
              }}
            ></img>
            <img
              alt="cancel"
              className={styles.actionIcnDel}
              src={CrossImg}
              onClick={() => {
                setIsEdit(false);
              }}
            ></img>
          </>
        ) : (
          <>
            <img
              alt="edit"
              data-testid={`edit-field-${item}`}
              className={styles.actionIcnEdit}
              src={EditImg}
              onClick={() => {
                setIsEdit(true);
                setSelectedAliasField(item);
                handleSelectEditVal(system_config?.buowDescription[selectedBuowField]?.indexSheet[item]?.type);
                setSelectEditRequire(system_config?.buowDescription[selectedBuowField]?.indexSheet[item]?.is_required);
              }}
            ></img>
            <img
              alt="delete"
              className={styles.actionIcnDel}
              src={DeleteImg}
              onClick={() => {
                setDeleteBuowField(item);
                setDeleteTitle('Field');
                setDeleteBuowModalShow(true);
              }}
            ></img>
          </>
        )}
      </li>
    );
  };

  useEffect(() => {
    seqArr?.map((item, idx) => {
      // Object.keys(system_config?.buowDescription[selectedBuowField]?.indexSheet[item].sequence = idx +1)
      setSystem_config((values) => ({
        ...values,
        buowDescription: {
          ...values.buowDescription,
          [selectedBuowField]: {
            ...values.buowDescription[selectedBuowField],
            indexSheet: {
              ...values.buowDescription[selectedBuowField].indexSheet,
              [item]: {
                ...values.buowDescription[selectedBuowField].indexSheet[item],
                sequence: idx + 1,
              },
            },
          },
        },
      }));
    });
  }, [seqArr]);

  const handleIsDefaultCond = (defaultFlag) => {
    indexSheetAliasBuow?.map((b, idx) => {
      if (b === selectedBuowField) {
        setSystem_config((values) => ({
          ...values,
          buowDescription: {
            ...values.buowDescription,
            [selectedBuowField]: {
              ...values.buowDescription[selectedBuowField],
              is_default: defaultFlag,
            },
          },
        }));
      } else {
        setSystem_config((values) => ({
          ...values,
          buowDescription: {
            ...values.buowDescription,
            [b]: {
              ...values.buowDescription[b],
              is_default: false,
            },
          },
        }));
      }
    });
  };

  return (
    <>
      <Modal
        {...props}
        className={[styles.systemConfigurationModal, `${selectedBuowField !== '' ? 'glbSystemConfigurationModalStyleCustom' : 'glbSystemConfigurationModalStyle'}`].join(' ')}
        backdropClassName="orc-modal-custom-backdrop"
        aria-labelledby="contained-modal-title-vcenter"
        size={'xl'}
        animation={false}
        onHide={() => {
          props.onHide();

          setSelectedImgFile(null);
        }}
      >
        <div data-testid="systemConfigContainer">
          <Modal.Header>
            <div className={styles.modalHeader}>
              <div>
                <h2 className={styles.modalHeaderTitle}>{props.isNew ? 'Create System Configuration' : 'Edit System Configuration'}</h2>
              </div>
              <div
                className={styles.modalHeaderIcon}
                onClick={() => {
                  props.setRecordModalShow(false);
                  setSelectedImg(null);
                  setisRequiredError(false);
                  setSelectedAliasField('');
                  setSelectedBuowField('');
                  setApiObject(null);
                  setIndexSheetAliasValues([]);
                  setIndexSheetAliasKeys([]);
                  setExternalServiceToggle(false);
                  clearExternalApiStates();
                }}
              >
                <img src={crossIcon} alt="cross icon" />
              </div>
            </div>
          </Modal.Header>
          <Modal.Body className={styles.systemConfigModalBody}>
            <div className={styles.modalBodySection}>
              <div className={styles.inputFieldSection}>
                <div className={[styles.inputFieldInternalDiv, 'col-3'].join(' ')}>
                  <label>Context Id *</label>
                  <input
                    data-testid="context-input"
                    required
                    name="contextId"
                    value={props.formData.contextId}
                    onChange={handleInputChange}
                    disabled={props.isNew ? false : true}
                  />
                  {(props.formData.contextId === null || props.formData.contextId === '') && isRequiredError === true && (
                    <div data-testid="context-error">{handleIsRequiredError()}</div>
                  )}
                  {props.formData.contextId?.length > 50 && isRequiredError === true && handleMaxLimitError(50)}
                </div>
                <div className={[styles.inputFieldInternalDiv, 'col-3'].join(' ')}>
                  <div className={styles.uploadImageSection}>
                    <label>Logo *</label>
                    <div className={styles.imageContents}>
                      <div className={styles.iconInput}>
                        <input data-testid="logo-input" type="file" name="logo" accept="image/jpeg, image/png, image/webp" onChange={handleIcon} />

                        {selectedImg && <img className={styles.imageStyle} src={selectedImg} alt="preview" width="400" height="200" />}
                        {props.formData.logo && <img className={styles.imageStyle} src={`data:image/jpeg;base64,${props.formData.logo}`} alt="preview" width="400" height="200" />}
                      </div>
                      {props.isNew && (selectedImg === null || selectedImg === '') && isRequiredError === true && (
                        <div data-testid="logo-error" style={{ marginLeft: '1rem' }} className="mb-3">
                          <span>{handleIsRequiredError()}</span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                {/* <div className={styles.inputFieldSection}> */}
                <div className={[styles.inputFieldInternalDiv, 'col-3'].join(' ')}>
                  <label>Title *</label>
                  <input data-testid="system-configuration-title" required name="title" value={props.formData.title} onChange={handleInputChange} />
                  {(props.formData.title === null || props.formData.title === '') && isRequiredError === true && <div data-testid="title-error">{handleIsRequiredError()}</div>}
                </div>
                <div className={[styles.inputFieldInternalDiv, 'col-3'].join(' ')}>
                  <label>Clipboard Name</label>
                  <input required name="clipBoardName" value={props.formData.clipBoardName} onChange={handleInputChange} />
                </div>
                {/* </div> */}
              </div>

              <div className={styles.inputFieldSection}>
                {!props.isNew && (
                  <div className={[styles.inputFieldInternalDiv, 'col-3'].join(' ')}>
                    <label>DRIMS Version</label>
                    <input id="drimsVersion" disabled value={props.formData.drimsVersion} />
                  </div>
                )}
                <div className={[styles.inputFieldInternalDiv, 'col-3'].join(' ')}>
                  <label>Rule View</label>
                  <select id="selectField" name="ruleViewSetting" value={props.formData.ruleViewSetting} onChange={handleSelectChange}>
                    <option value={''} disabled>
                      ---Select View--
                    </option>
                    <option value={'page'}>Page</option>
                    <option value={'doc'}>Document</option>
                  </select>
                </div>
              </div>
              <div className={styles.inputFieldSection}>
                <div className={['row', styles.inputFieldInternalDiv, styles.tabNavSysConf].join(' ')} style={{ flexDirection: 'row' }}>
                  <p
                    style={{ cursor: 'pointer' }}
                    className={['col', styles.buowList, `${selectedNavPill === 'BUOW' ? styles.buowNavPillActive : styles.buowNavPill}`].join(' ')}
                    onClick={() => {
                      setSelectedNavPill('BUOW');
                    }}
                  >
                    BUOW
                  </p>
                  <p
                    style={{ cursor: 'pointer' }}
                    className={['col', styles.buowList, `${selectedNavPill === 'AdminPanel' ? styles.buowNavPillActive : styles.buowNavPill}`].join(' ')}
                    onClick={() => {
                      setSelectedNavPill('AdminPanel');
                    }}
                  >
                    Admin Panel
                  </p>
                  <p
                    style={{ cursor: 'pointer' }}
                    className={['col', styles.buowList, `${selectedNavPill === 'SystemConfiguration' ? styles.buowNavPillActive : styles.buowNavPill}`].join(' ')}
                    onClick={() => {
                      setSelectedNavPill('SystemConfiguration');
                    }}
                  >
                    System Feature
                  </p>

                  {/* </div> */}
                  {/* <div className={['row', styles.inputFieldInternalDiv].join(' ')} style={{ flexDirection: 'row', justifyContent: 'space-evenly' }}> */}
                </div>
              </div>
              <div className={[styles.inputFieldSection, styles.navModuleContainer].join(' ')}>
                {selectedNavPill === 'BUOW' && (
                  <div className={[styles.inputFieldInternalDiv].join(' ')} style={{ margin: '0.5rem', width: '100%' }}>
                    <div className={styles.buowContainer}>
                      <div>
                        <div className={styles.addBuowContainer}>
                          <input
                            data-testid="add-buow-input"
                            ref={inputBuowRef}
                            className={styles.indexListInputBuow}
                            defaultValue={''}
                            name={''}
                            // onClick={() => {
                            //   clearAlias();
                            // }}
                            onChange={(e) => {
                              setinputBuowInput(e.target.value);
                            }}
                            placeholder={'Add BUOW'}
                          ></input>
                          <button
                            data-testid="add-buow-btn"
                            className={styles.addBtn}
                            onClick={() => {
                              saveFieldBuow();
                            }}
                            disabled={inputBuowInput === '' && inputBuowInput !== null ? true : false}
                          >
                            Add
                          </button>
                          <span className={styles.mandatoryAsterisk}>*</span>
                        </div>
                        {props.isNew && indexSheetAliasBuow.length === 0 && isRequiredError === true && <span data-testid="buow-error">{handleIsRequiredError()}</span>}
                      </div>
                      <ul data-testid="buow-list-container" className={[styles.buowList].join(' ')}>
                        {system_config?.buowDescription !== null &&
                          indexSheetAliasBuow.map((itm) => (
                            <li className={styles.buowPills} key={itm}>
                              <div
                                data-testid={`buow-div-${itm}`}
                                // Apply distinct styles for default and selected BUOW directly within map
                                className={`${selectedBuowField === itm ? styles.linkBtnBuowActive : styles.linkBtnBuow}
                      ${system_config?.buowDescription[itm]?.is_default && selectedBuowField !== itm ? styles.defaultBuowHighlight : ''}`}
                                onClick={() => {
                                  setBuowTypeValue('');
                                  setBuowSubject('');
                                  setIsEdit(false);
                                  setIsEditAlias(false);
                                  setSelectedBuowField(itm);
                                  setApiObject(null);
                                  setEndPointReq(false);
                                  setUrlReq(false);
                                  setHttpReq(false);
                                  selectedBuowField !== itm && clearExternalApiStates();
                                  // setSelectedAliasField(
                                  //   Object.keys(system_config.buowDescription[itm].indexSheet)[0] !== undefined ? Object.keys(system_config.buowDescription[itm].indexSheet)[0] : ''
                                  // );
                                  if (Object.keys(system_config.buowDescription[itm].indexSheet) !== undefined) {
                                    Object.keys(system_config.buowDescription[itm].indexSheet).forEach((ele) => {
                                      if (system_config.buowDescription[itm].indexSheet[ele].sequence === 1) {
                                        setSelectedAliasField(ele);
                                      }
                                    });
                                  } else {
                                    setSelectedAliasField('');
                                  }
                                  setBuowTypeReq(false);
                                }}
                              >
                                {itm}
                                <div className={selectedBuowField === itm ? styles.arrowContainerActive : styles.arrowContainer}>
                                  <img src={arrowImg} alt="Arrow" />
                                </div>
                              </div>
                            </li>
                          ))}
                      </ul>
                    </div>

                    {selectedBuowField !== '' && (
                      <>
                        {/* {externalServiceToggle === false && ( */}
                          <>
                            <div data-testid="buow-field-section" className={[styles.inputFieldSection, ''].join(' ')}>
                              <div data-testid="buow-type-container" className={[styles.inputFieldInternalDiv1, 'col-3'].join(' ')}>
                                <label>BUOW Type *</label>
                                <AsyncSelect
                                  name="buowType"
                                  value={
                                    system_config?.buowDescription[selectedBuowField]?.buow_type === undefined
                                      ? ''
                                      : { value: system_config?.buowDescription[selectedBuowField]?.buow_type, label: system_config?.buowDescription[selectedBuowField]?.buow_type }
                                  }
                                  onChange={handleBuowTypeChange}
                                  defaultOptions={buowType}
                                  placeholder="Select File Type"
                                  styles={customStyles}
                                />
                                {buowTypeReq && (
                                  <span data-testid="buow-type-error" className="requiredErrorMsgStyle">
                                    This field is required.
                                  </span>
                                )}
                              </div>
                              <div className={[styles.inputFieldInternalDiv1, 'col-3'].join(' ')}>
                                <label>BUOW Subject</label>
                                <input
                                  data-testid="buow-subject-input"
                                  value={system_config?.buowDescription[selectedBuowField]?.buow_subject ? system_config?.buowDescription[selectedBuowField]?.buow_subject : ''}
                                  onChange={handleBuowSubject}
                                  name="buowSubject"
                                />
                              </div>
                            </div>
                            {/* Toggle Buttons Start */}
                            <div className={[styles.inputFieldSection, ''].join(' ')}>
                              <div className={[styles.inputFieldInternalDiv1, 'col-3'].join(' ')}>
                                <div className={styles.toggleFieldInternalDiv1} data-testid="indexsheet-toggle-container" style={{ justifyContent: 'space-between' }}>
                                  <label className="mr-2">
                                    <div style={{ width: '16rem' }}>Index Sheet</div>
                                  </label>
                                  <Switch
                                    onChange={(e) => {
                                      handleToggleIndex(e, 'index');
                                    }}
                                    onLabel={''}
                                    offLabel={''}
                                    checked={indexSheetToggle}
                                  />
                                </div>
                              </div>
                              <div className={[styles.inputFieldInternalDiv1, 'col-3'].join(' ')}>
                                <div className={styles.toggleFieldInternalDiv1} data-testid="separator-toggle-container" style={{ justifyContent: 'space-between' }}>
                                  <label className="mr-2">
                                    <div style={{ width: '16rem' }}>Separator Sheet</div>
                                  </label>
                                  <Switch
                                    onChange={(e) => {
                                      handleToggleIndex(e, 'separator');
                                    }}
                                    onLabel={''}
                                    offLabel={''}
                                    checked={separatorToggle}
                                  />
                                </div>
                              </div>
                              <div className={[styles.inputFieldInternalDiv1, 'col-3'].join(' ')}>
                                <div className={styles.toggleFieldInternalDiv1} data-testid="isDefault-toggle-container" style={{ justifyContent: 'space-between' }}>
                                  <label className="mr-2">
                                    <div style={{ width: '16rem' }}>Make it Default</div>
                                  </label>
                                  <Switch
                                    onChange={(e) => {
                                      handleToggleIndex(e, 'default');
                                    }}
                                    onLabel={''}
                                    offLabel={''}
                                    disabled={
                                      Object.keys(system_config?.buowDescription).length < 2
                                        ? true
                                        : system_config?.buowDescription[selectedBuowField]?.is_default === true
                                        ? true
                                        : false
                                    }
                                    checked={isDefaultToggle}
                                  />
                                </div>
                              </div>
                            </div>

                            {/* Toggle Buttons End */}
                            <MakeItDefaultModal
                              show={makeItDefaultModalShow}
                              setMakeItDefaultModalShow={setMakeItDefaultModalShow}
                              isDefaultToggle={isDefaultToggle}
                              setIsDefaultToggle={setIsDefaultToggle}
                              handleIsDefaultCond={handleIsDefaultCond}
                            />
                            <div className={['row', styles.inputFieldSection].join(' ')} style={{ minWidth: '100%', justifyContent: 'center' }}>
                              {
                                <div
                                  className={[
                                    // `${selectedAliasField !== '' ? 'col-3' : 'col-2'}`
                                    'col',
                                    styles.indexSheetList,
                                  ].join(' ')}
                                >
                                  <div className={styles.listHead}>
                                    <input
                                      data-testid="buow-field-name"
                                      className={styles.textInputBuow}
                                      disabled={selectedBuowField === '' ? true : false}
                                      value={fieldIP}
                                      placeholder="Add Field Name"
                                      onChange={(e) => {
                                        setFieldIP(e.target.value);
                                      }}
                                    />
                                    <select
                                      data-testid="buow-field-type-select"
                                      className={styles.textInputBuow}
                                      defaultValue={selectType}
                                      name="types"
                                      onChange={(e) => {
                                        handleSelectType(e, 'add');
                                      }}
                                    >
                                      <option value={'text'}>Text</option>
                                      <option value={'boolean'}>Boolean</option>
                                      <option value={'date'}>Date</option>
                                      <option value={'number'}>Number</option>
                                    </select>
                                    <select
                                      data-testid="buow-field-require"
                                      className={styles.textInputBuow}
                                      value={selectRequired ? true : false}
                                      name="required"
                                      onChange={(e) => {
                                        handleSelectType(e, 'require');
                                      }}
                                    >
                                      <option value={true}>Required</option>
                                      <option value={false}>Not Required</option>
                                    </select>
                                    <button
                                      data-testid="add-field-btn"
                                      className={styles.addBtn}
                                      onClick={() => {
                                        if (fieldIP.trim() !== '') {
                                          let tempObj = system_config?.buowDescription[selectedBuowField].indexSheet;
                                          Object.assign(tempObj, {
                                            [fieldIP]: {
                                              alias: [],
                                              sequence: Object.keys(system_config?.buowDescription[selectedBuowField].indexSheet)?.length + 1,
                                              type: selectType,
                                              is_required: selectRequired,
                                            },
                                          });
                                          setSystem_config((values) => ({
                                            ...values,
                                            buowDescription: {
                                              ...values.buowDescription,
                                              [selectedBuowField]: {
                                                ...values.buowDescription[selectedBuowField],
                                                indexSheet: tempObj,
                                              },
                                            },
                                          }));
                                          setSelectedAliasField('');
                                          setFieldIP('');
                                        }
                                        setSelectType('text');
                                        setSelectRequired(true);
                                      }}
                                      disabled={selectedBuowField === '' ? true : false}
                                    >
                                      Add
                                    </button>
                                  </div>
                                  <ul className={styles.indexList}>
                                    {indexSheetAliasKeys.length !== 0 &&
                                      indexSheetAliasKeys.map((itm, idx) => {
                                        return renderFieldName(itm, idx);
                                      })}
                                  </ul>
                                </div>
                              }
                              {
                                <div className={['col', styles.indexSheetList].join(' ')}>
                                  <div className={styles.listHead}>
                                    <input
                                      className={styles.textInputBuow}
                                      disabled={selectedAliasField === '' ? true : false}
                                      value={aliasFieldIP}
                                      placeholder="Add Alias Name"
                                      onChange={(e) => {
                                        setAliasFieldIP(e.target.value);
                                      }}
                                    />
                                    <button
                                      className={styles.addBtn}
                                      onClick={() => {
                                        if (aliasFieldIP.trim() !== '') {
                                          let tempArr = [...system_config?.buowDescription[selectedBuowField].indexSheet[selectedAliasField]?.alias];
                                          tempArr.push(aliasFieldIP);
                                          setSystem_config((values) => ({
                                            ...values,
                                            buowDescription: {
                                              ...values.buowDescription,
                                              [selectedBuowField]: {
                                                ...values.buowDescription[selectedBuowField],
                                                indexSheet: {
                                                  ...values.buowDescription[selectedBuowField].indexSheet,
                                                  [selectedAliasField]: {
                                                    ...values.buowDescription[selectedBuowField].indexSheet[selectedAliasField],
                                                    alias: tempArr,
                                                  },
                                                },
                                              },
                                            },
                                          }));
                                          setAliasFieldIP('');
                                        }
                                      }}
                                      disabled={selectedAliasField === '' ? true : false}
                                    >
                                      Add
                                    </button>
                                  </div>
                                  <ul className={styles.indexList} key={system_config}>
                                    {selectedAliasField !== '' &&
                                      indexSheetAliasValues?.map((itm) => {
                                        return (
                                          <li className={styles.indexListPill}>
                                            {selectedAliasFieldKey === itm && isEditAlias === true ? (
                                              <input
                                                ref={inputAliasRef}
                                                className={styles.indexListInput}
                                                defaultValue={itm}
                                                name={itm}
                                                onClick={() => {
                                                  setSelectedAliasFieldKey(itm);
                                                }}
                                                placeholder={`${itm === '' && 'Enter Field Name'}`}
                                              ></input>
                                            ) : (
                                              <p
                                                className={styles.indexListLabel}
                                                onClick={() => {
                                                  setSelectedAliasFieldKey(itm);
                                                  setIsEditAlias(false);
                                                }}
                                              >
                                                {itm === '' ? 'Enter Field Name' : itm}
                                              </p>
                                            )}
                                            {selectedAliasFieldKey === itm && isEditAlias === true ? (
                                              <>
                                                <img
                                                  alt="save"
                                                  className={styles.actionIcnEdit}
                                                  src={RightImg}
                                                  onClick={(e) => {
                                                    saveFieldAlias(inputAliasRef);
                                                  }}
                                                ></img>
                                                <img
                                                  alt="cancel"
                                                  className={styles.actionIcnDel}
                                                  src={CrossImg}
                                                  onClick={() => {
                                                    setIsEditAlias(false);
                                                  }}
                                                ></img>
                                              </>
                                            ) : (
                                              <>
                                                <img
                                                  alt="edit"
                                                  className={styles.actionIcnEdit}
                                                  src={EditImg}
                                                  onClick={() => {
                                                    setIsEditAlias(true);
                                                    setSelectedAliasFieldKey(itm);
                                                  }}
                                                ></img>
                                                <img
                                                  alt="delete"
                                                  className={styles.actionIcnDel}
                                                  src={DeleteImg}
                                                  onClick={() => {
                                                    setDeleteAliasField(itm);
                                                    setDeleteTitle('Alias Field');
                                                    setDeleteBuowModalShow(true);
                                                  }}
                                                ></img>
                                              </>
                                            )}
                                          </li>
                                        );
                                      })}
                                  </ul>
                                </div>
                              }
                            </div>
                          </>
                         {/* )} */}
                        <div className={[styles.inputFieldSection, styles.inputFieldSectionAddOn].join(' ')} style={{ flexWrap: 'wrap', gap: '2rem 0rem', padding: 0 }}>
                          <div className={[styles.inputFieldInternalDiv, 'col-3'].join(' ')} style={{ padding: 0 }}>
                            <div data-testid="external-switch-container" className={[styles.toggleFieldInternalDiv, styles.externalServicesToggle].join(' ')}>
                              <label className="mr-2">Submit To External Services</label>
                              <Switch
                                onChange={(e) => {
                                  setExternalServiceToggle(!externalServiceToggle);
                                }}
                                disabled={false}
                                onLabel={''}
                                offLabel={''}
                                checked={system_config.buowDescription[selectedBuowField].is_external_service_enabled}
                              />
                            </div>
                          </div>
                        </div>
                        <div className={[styles.externalServiceBorderContainer].join(' ')}>
                          <div className={[styles.externalServiceBorder].join(' ')}></div>
                        </div>
                        {system_config.buowDescription[selectedBuowField].is_external_service_enabled && (
                          <div data-testid="external-service-container" className={styles.externalServiceFormContainer}>
                            <div style={{ justifyContent: 'center' }} className={[styles.externalApiRow, ''].join(' ')}>
                              <div data-testid="endpoint-input-container" className={['col-2'].join(' ')}>
                                <label data-testid="endpoint-label">Endpoint Name *</label>
                                <input
                                  data-testid="endpoint-input"
                                  defaultValue={
                                    system_config?.buowDescription[selectedBuowField]?.external_api_details?.endpointName
                                      ? system_config?.buowDescription[selectedBuowField]?.external_api_details?.endpointName
                                      : ''
                                  }
                                  onChange={externalServiceTextHandler}
                                  name="endPointName"
                                />
                                {endPointReq && (
                                  <span data-testid="endpoint-error" className="requiredErrorMsgStyle">
                                    This field is required.
                                  </span>
                                )}
                              </div>
                              <div data-testid="url-input-container" className={['col-3'].join(' ')}>
                                <label data-testid="url-label">URL *</label>
                                <input
                                  data-testid="url-input"
                                  defaultValue={
                                    system_config?.buowDescription[selectedBuowField]?.external_api_details?.url
                                      ? system_config?.buowDescription[selectedBuowField]?.external_api_details?.url
                                      : ''
                                  }
                                  onChange={externalServiceTextHandler}
                                  name="url"
                                />
                                {urlReq ? (
                                  <span data-testid="url-error" className="requiredErrorMsgStyle">
                                    This field is required.
                                  </span>
                                ) : (
                                  invalidUrl && (
                                    <span data-testid="url-validation-error" className="requiredErrorMsgStyle">
                                      Enter Valid Url
                                    </span>
                                  )
                                )}
                              </div>
                              <div data-testid="http-input-container" style={{ paddingRight: '0px' }} className={['col-2'].join(' ')}>
                                <label data-testid="http-label">HTTP Method *</label>
                                <AsyncSelect
                                  name="httpMethod"
                                  value={
                                    system_config?.buowDescription[selectedBuowField]?.external_api_details?.httpMethod !== ''
                                      ? {
                                          value: system_config?.buowDescription[selectedBuowField]?.external_api_details?.httpMethod,
                                          label: system_config?.buowDescription[selectedBuowField]?.external_api_details?.httpMethod,
                                        }
                                      : ''
                                  }
                                  onChange={externalServiceHttpDropdownHandler}
                                  loadOptions={(inputValue, callback) => {
                                    if (inputValue === '') {
                                      let options = [];
                                      httpMethodType.map((res) => {
                                        options.push({ label: res.label, value: res.value });
                                        setTimeout(() => {
                                          callback(options);
                                        }, 1000);
                                      });
                                      callback(options);
                                    } else {
                                      let options = [];
                                      httpMethodTypeFilter(inputValue).map((res) => {
                                        options.push({ label: res.label, value: res.value });
                                        setTimeout(() => {
                                          callback(options);
                                        }, 1000);
                                      });
                                      callback(options);
                                    }
                                  }}
                                  defaultOptions={httpMethodType}
                                  placeholder="Select HTTP Method"
                                  styles={customStylesHttp}
                                />
                                {httpReq && (
                                  <span data-testid="http-error" className="requiredErrorMsgStyle">
                                    This field is required.
                                  </span>
                                )}
                              </div>
                            </div>

                            <div style={{ justifyContent: 'center' }} className={[styles.externalApiRow, ''].join(' ')}>
                              <div data-testid="header-input-container" style={{ paddingRight: 0 }} className={['col-7'].join(' ')}>
                                <label data-testid="header-label">Headers</label>
                                <textarea
                                  data-testid="header-input"
                                  defaultValue={
                                    system_config?.buowDescription[selectedBuowField]?.external_api_details?.headers
                                      ? system_config?.buowDescription[selectedBuowField]?.external_api_details?.headers
                                      : ''
                                  }
                                  onChange={externalServiceTextHandler}
                                  name="headers"
                                  className={styles.textAreaHeader}
                                />
                              </div>
                            </div>
                            <div style={{ justifyContent: 'center' }} className={[styles.externalApiRow, ''].join(' ')}>
                              <div data-testid="query-input-container" className={['col-4'].join(' ')}>
                                <label data-testid="query-label">Query Parameters</label>
                                <input
                                  data-testid="query-input"
                                  defaultValue={
                                    system_config?.buowDescription[selectedBuowField]?.external_api_details?.queryParams
                                      ? system_config?.buowDescription[selectedBuowField]?.external_api_details?.queryParams
                                      : ''
                                  }
                                  onChange={externalServiceTextHandler}
                                  name="queryParams"
                                />
                              </div>
                              <div data-testid="auth-type-input-container" style={{ paddingRight: '0px' }} className={['col-3'].join(' ')}>
                                <label data-testid="auth-type-label">Authentication Type</label>
                                <AsyncSelect
                                  name="AuthenticationType"
                                  value={
                                    system_config?.buowDescription[selectedBuowField]?.external_api_details?.authenticationType !== ''
                                      ? {
                                          value: system_config?.buowDescription[selectedBuowField]?.external_api_details?.authenticationType,
                                          label: system_config?.buowDescription[selectedBuowField]?.external_api_details?.authenticationType,
                                        }
                                      : ''
                                  }
                                  loadOptions={(inputValue, callback) => {
                                    if (inputValue === '') {
                                      let options = [];
                                      authType.map((res) => {
                                        options.push({ label: res.label, value: res.value });
                                        setTimeout(() => {
                                          callback(options);
                                        }, 1000);
                                      });
                                      callback(options);
                                    } else {
                                      let options = [];
                                      authTypeFilter(inputValue).map((res) => {
                                        options.push({ label: res.label, value: res.value });
                                        setTimeout(() => {
                                          callback(options);
                                        }, 1000);
                                      });
                                      callback(options);
                                    }
                                  }}
                                  onChange={externalServiceAuthTypeDropdownHandler}
                                  defaultOptions={authType}
                                  placeholder="Select Authentication Type"
                                  styles={customStylesAuth}
                                />
                              </div>
                            </div>
                            <div style={{ justifyContent: 'center' }} className={[styles.externalApiRow, ''].join(' ')}>
                              <div data-testid="bodyParams-input-container" className={['col-4'].join(' ')}>
                                <label data-testid="bodyParams-label">Body Parameters</label>
                                <textarea
                                  data-testid="bodyParams-input"
                                  className={styles.textAreaHeader}
                                  defaultValue={
                                    system_config?.buowDescription[selectedBuowField]?.external_api_details?.bodyParams
                                      ? system_config?.buowDescription[selectedBuowField]?.external_api_details?.bodyParams
                                      : ''
                                  }
                                  onChange={externalServiceTextHandler}
                                  name="bodyParams"
                                />
                              </div>
                              <div style={{ paddingRight: 0 }} data-testid="buow-type-container" className={['col-3'].join(' ')}>
                                <label>Description</label>
                                <textarea
                                  className={styles.textAreaHeader}
                                  defaultValue={
                                    system_config?.buowDescription[selectedBuowField]?.external_api_details?.description
                                      ? system_config?.buowDescription[selectedBuowField]?.external_api_details?.description
                                      : ''
                                  }
                                  onChange={externalServiceTextHandler}
                                  name="description"
                                />
                              </div>
                            </div>

                            <div style={{ justifyContent: 'center' }} className={[styles.externalApiRow, ''].join(' ')}>
                              <div style={{ paddingRight: 0 }} className={['col-7'].join(' ')}>
                                <label>Notes</label>
                                <textarea
                                  defaultValue={
                                    system_config?.buowDescription[selectedBuowField]?.external_api_details?.notes
                                      ? system_config?.buowDescription[selectedBuowField]?.external_api_details?.notes
                                      : ''
                                  }
                                  onChange={externalServiceTextHandler}
                                  name="notes"
                                  className={styles.textAreaHeader}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                )}
                {selectedNavPill === 'AdminPanel' && (
                  <div className={[styles.inputFieldSection, styles.inputFieldSectionAddOn].join(' ')} style={{ flexWrap: 'wrap', gap: '2rem 0rem', padding: 0 }}>
                    {props?.formData?.adminPanel && (
                      <>
                        {props?.formData?.adminPanel.map((item, index) => {
                          return (
                            <div key={index} className={[styles.inputFieldInternalDiv, 'col-3'].join(' ')} style={{ padding: 0 }}>
                              <div className={styles.toggleFieldInternalDiv}>
                                <label className="mr-2">
                                  <div style={{ width: '16rem' }}>{item?.title}</div>
                                </label>
                                <Switch
                                  onChange={(e) => {
                                    handleToggleChange(e, index, 'adminPanel');
                                  }}
                                  disabled={item?.title === 'System Configuration' ? true : false}
                                  // defaultChecked={true}
                                  onLabel={''}
                                  offLabel={''}
                                  checked={item?.value}
                                />
                              </div>
                            </div>
                          );
                        })}
                      </>
                    )}
                  </div>
                )}
                {selectedNavPill === 'SystemConfiguration' && (
                  <div className={[styles.inputFieldSection, styles.inputFieldSectionAddOn].join(' ')} style={{ flexWrap: 'wrap', gap: '2rem 0rem', padding: 0 }}>
                    {props?.formData?.features && (
                      <>
                        {props?.formData?.features.map((item, index) => {
                          return (
                            <div key={index} className={[styles.inputFieldInternalDiv, 'col-3'].join(' ')}>
                              <div className={styles.toggleFieldInternalDiv}>
                                <label className="mr-2">
                                  <div style={{ width: '16rem' }}>{item?.title}</div>
                                </label>

                                <Switch
                                  onChange={(e) => {
                                    handleToggleChange(e, index, 'features');
                                  }}
                                  defaultChecked={true}
                                  onLabel={''}
                                  offLabel={''}
                                  checked={item?.value}
                                />
                              </div>
                            </div>
                          );
                        })}
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className={styles.orchestrationFooter}>
            <div style={{ display: 'flex', gap: '3rem' }} className={['col-12 justify-content-center', styles.footerInternalDiv].join(' ')}>
              {props.isNew ? (
                <CustomButton title="Save" testId={'save-systemConfig'} className={styles.saveRecordBtn} onClick={handleSubmit} />
              ) : (
                <CustomButton title="Update" className={styles.saveRecordBtn} onClick={handleEdit} />
              )}
              <CustomButton
                title="Cancel"
                className={styles.cancelRecordBtn}
                onClick={() => {
                  props.setRecordModalShow(false);
                  setSelectedImg(null);
                  setisRequiredError(false);
                  setSelectedAliasField('');
                  setSelectedBuowField('');
                  setApiObject(null);
                  setExternalServiceToggle(false);
                  clearExternalApiStates();
                }}
              />
            </div>
          </Modal.Footer>
        </div>
      </Modal>
      {deleteBuowModalShow && (
        <div className={styles.overlay}>
          <DeleteModal
            show={deleteBuowModalShow}
            DeleteDataFunc={deleteFields}
            onHide={() => setDeleteBuowModalShow(false)}
            setDeleteModalShow={setDeleteBuowModalShow}
            popupTitle={deleteTitle}
          ></DeleteModal>
        </div>
      )}
    </>
  );
};
// END:- Create / Edit record modal with functionality

// START:- Make It Default BUOW Modal
const MakeItDefaultModal = (props) => {
  return (
    <Modal
      {...props}
      className={[styles.makeItDefaultModal, 'glbMakeItDefaultModalStyle'].join(' ')}
      backdropClassName="orc-modal-custom-backdrop"
      aria-labelledby="contained-modal-title-vcenter"
      size="md"
      centered
      onHide={() => {
        props.setMakeItDefaultModalShow(false);
      }}
    >
      <div>
        <Modal.Header>
          <div className={styles.modalHeader}>
            <div>
              <h2 className={styles.modalHeaderTitle}>Make It Default BUOW</h2>
            </div>
            <div
              className={styles.modalHeaderIcon}
              onClick={() => {
                props.setMakeItDefaultModalShow(false);
              }}
            >
              <img src={crossIcon} alt="cross icon" />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className={styles.makeItDefaultModalBody}>
          <div className={styles.makeItDefaultContainer}>
            <div className={styles.modalBodySection}>Are you sure?</div>
            <div className={styles.confirmationText}>{`Do you really want to ${
              props.isDefaultToggle === true ? 'Remove' : 'Make'
            } this BUOW Default? click Yes button to confirm`}</div>
          </div>
        </Modal.Body>
        <Modal.Footer className={styles.makeItDefaultFooter}>
          <div style={{ display: 'flex', gap: '3rem' }} className={['col-12 justify-content-center', styles.footerInternalDiv].join(' ')}>
            <CustomButton
              title="Yes"
              className={styles.yesBtn}
              onClick={() => {
                props.setIsDefaultToggle(!props.isDefaultToggle);
                props.setMakeItDefaultModalShow(false);
                props.handleIsDefaultCond(!props.isDefaultToggle);
              }}
            />

            <CustomButton
              title="No"
              className={styles.noBtn}
              onClick={() => {
                props.setMakeItDefaultModalShow(false);
              }}
            />
          </div>
        </Modal.Footer>
      </div>
    </Modal>
  );
};
// END:- Make It Default BUOW Modal
